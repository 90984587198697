<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">
            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>{{titleName}}
            </div>
            <!-- 查询框 -->
            <el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6" :label-width="labelWidth">
                <el-row class="flex">
                    <el-select v-model="searchData.year" placeholder="请选择" size="small">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 12rem;"
                        placeholder="幼儿园名称">
                    </el-input>
                    <el-button type="primary" plain size="small" style="background-color: #706fa8c3;color: white;"
                        icon="el-icon-search" @click="search()">搜索</el-button>
                    <el-button type="primary" size="small" style="background-color: white;color: black;" @click="reset(),searchData={}"
                        icon="el-icon-refresh" plain>重置</el-button>
                    <!-- <el-button type="primary" size="small" style="background-color: #000000a6;color: white;"
                        icon="el-icon-sort" plain>导入</el-button> -->
                        <div class="flex justify-end text-xs mt-4" v-if="tableData[0].update_time">最近全部班级更新时间:{{tableData[0].update_time}}</div>
                </el-row>
            </el-form>
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane :label="'全部'+all" name="first">
                    <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
                        <el-table-column prop="id" label="" min-width="10%">
                        </el-table-column>
                        <el-table-column prop="year" label="入学年份" min-width="10%">
                        </el-table-column>
                        <el-table-column prop="name" label="班级名称" min-width="10%">
                        </el-table-column>
                        <el-table-column prop="count" label="班级人数" min-width="10%">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间" min-width="20%">
                        </el-table-column>
                        <el-table-column prop="status" label="更新班级名称与学生信息" min-width="15%">
                            <template slot-scope="scope">
                                <el-button size="small" v-loading.fullscreen.lock="fullscreenLoading" type="text"
                                    @click="update(scope.row)" :style="{color:scope.row.is_update==0?'':'red'}">{{scope.row.is_update==0?'一天之内未更新':'一天之内已更新'}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            <!-- 分页 -->
            <div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
                <div></div>
                <Pagination :currentPage="currentPage" :pageSize="pageSize" :total="total" @pageChange="pageChange" />
            </div>
        </el-card>


    </div>
</template>

<script>
    import Pagination from "../../components/Pagination/pagination";
    import tableExamine from "../index/components/tableImport.vue"
    export default {
        data() {
            return {
                form: {
                    imgUrl: "", //图片字段
                    platName: "", //自定义字段
                },
                activeName: 'first',
                labelWidth: "100px",
                searchData: {
                    name: '',
                    createTime: [],
                    year: ''
                },
                tableData: [],
                options: [
                    {
                        value: '2018',
                        label: '2018'
                    },
                    {
                        value: '2019',
                        label: '2019'
                    },
                    {
                        value: '2020',
                        label: '2020'
                    },
                    {
                        value: '2021',
                        label: '2021'
                    },
                    {
                        value: '2022',
                        label: '2022'
                    },
                    {
                        value: '2023',
                        label: '2023'
                    },
                    {
                        value: '2024',
                        label: '2024'
                    },
                ],
                // 对话框
                isAdd: false, //区分添加和编辑
                dialog: false,
                // 分页
                total: 0,
                currentPage: 1,
                pageSize: 10,
                nursery_id: '',
                fullscreenLoading: false,
                titleName: ''
            };
        },
        created(){
            this.titleName = localStorage.getItem("kindName2")
            this.nursery_id = localStorage.getItem("nursery_id")
            this.getList()
            document.title=this.titleName
            // let month=new Date().getMonth()+1
            // let day=new Date().getDate()
            // console.log(this.tableData.update_time.slice(5,7))
            // let month2=month-this.tableData.update_time.slice(5,7)
        },
        components: {
            tableExamine,
            Pagination
        },
        watch: {},
        mounted() {
            
        },
        methods: {
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            //重置
            reset() {
                this.searchData.name = ""
                this.pageSize = 10
                this.currentPage = 1
                this.getList()
            },
            //更新
            update(row) {
                this.$confirm("是否更新", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: '数据导入需要2~5分钟，请耐心等待',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    const res = await this.http.post("admin/Impclass/update", {
                        school_id: localStorage.getItem("kingId"),
                        nursery_id: this.nursery_id,
                        class_id: row.id,
                        dept_id: row.dept_id,
						nursery:this.titleName,
						class:row.name
                    })
					console.log(res.code);
					
                    if (res.code == 200) {
                        loading.close()
                        this.$message({
                            showClose: true,
                            message: '更新成功',
                            type: 'success'
                        });
                        this.getList()
                    }else if(res.code==400){
						
                        loading.close()
                        this.getList()
                        this.$message.error(res.msg)
                    }else{
                        this.$message.error("错误，请重试")
                        loading.close()
                        this.getList()
                    }
                })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消更新",
                        });
                    })

            },
            // 获取基本数据
            async getList() {
                const RES = await this.http.post("admin/Impclass/all", {
                    limit: this.pageSize,
                    page: this.currentPage,
                    nursery_id: this.nursery_id
                });
                if (RES.code == 200) {
                    this.tableData = RES.data
                    this.total = RES.total
					this.all=RES.total
                }
            },
            // 编辑
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
            },
            //分页
            pageChange(index) {
                this.currentPage = index._currentPage;
                this.getList();
            },
            // 确认
            handleConfirm() {
                var a = { imgUrl: this.form.imgUrl, platName: this.form.platName }; //检查必填字段
                if (this.check.checkHasEmpty(a)) {
                    //调用函数checkHasEmpty() 检查必填字段是否有为空的
                    this.$message("请将数据填写完整");
                } else {
                    this.addImg();
                }
            },
            //查询
            search() {
                this.currentPage = 1;
                this.commonData();
            },
            // 分页&&查询通用数据 分页查询的情况比较多 ，坑比较多 ，建议和我写成一样或者自我优化
            async commonData() {
                if (this.searchData.createTime == null) {
                    this.searchData.createTime = [];
                }
                const RES = await this.http.post("admin/Impclass/search", {
                    limit: this.pageSize,
                    page: this.currentPage,
                    name: this.searchData.name,
                    nursery_id: this.nursery_id,
                    year: this.searchData.year,
                });
                if (RES.code == 200 || RES.code == 204) {
                    this.tableData = RES.data;
                    this.total = RES.total;
                }
                if (RES.code == 400) {
                    this.$message({
                        message: '暂无数据，请重新选择查询条件',
                        type: 'warning'
                    });
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    /deep/.el-dialog__headerbtn .el-dialog__close {
        color: black;
    }

    /deep/.el-dialog {
        height: 400px;
    }

    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }

    /deep/.el-tabs__header {
        background-color: #d9dceca3;
    }

    /deep/.el-dialog__headerbtn {
        top: 10px;
    }

    /deep/.el-dialog__title {
        color: white;
        font-size: 1rem;
    }

    /deep/.el-table th {
        background-color: white !important;
    }

    /deep/.el-tabs__nav {}

    /deep/.el-tabs__item.is-active {
        background-color: #d4caf1;
        color: #535c8d;
    }

    /deep/.el-tabs__active-bar {
        background-color: #535c8d;
		margin-left: -1.8rem;
    }

    /deep/.el-table td,
    .el-table th {
        text-align: center;
    }

    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding: 0 30px;
    }

    /deep/.el-table th {
        text-align: center;
        background: rgb(236, 235, 235);
    }

    /deep/ .avatar-uploader .el-upload {
        border: 1px dashed hsl(0, 0%, 85%);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: rgb(204, 204, 204);
    }

    /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    /deep/ .avatar {
        width: 200px;
        height: 150px;
        display: block;
    }

    /deep/.el-form-item {
        margin-bottom: 5px;
    }

    /deep/.el-dialog__body {
        padding: 0px;
    }

    /deep/.el-divider--horizontal {
        margin: 0px;
    }


    /deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

      /deep/.el-tabs__item.is-active {
        background-color: #ecedf3;
        color: #535c8d;
    }
    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }
    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        margin-left: -1.8rem;
    }
    /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>